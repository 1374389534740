let bW = document.documentElement.clientWidth,
  bH = document.documentElement.clientHeight;

window.onload = () => {
  init();
  resize();
}

const init = () => {
  setHam();
  setSlider();
  setFeatureModal();
  setThemeModal();
  setNews();
  setScroll();
}

const resize = () => {
  window.addEventListener('resize', () => {
    bW = document.documentElement.clientWidth;
    bH = document.documentElement.clientHeight;
  })
}

const setHam = () => {
  const ham = document.querySelector('.l-header-ham'),
    links = document.querySelectorAll('.l-header-nav__main a'),
    header = document.querySelector('.l-header');
  ham.addEventListener('click', function (e) {
    e.preventDefault();
    header.classList.toggle('is-open')
  })
  for (const link of links) {
    link.addEventListener('click', () => {
      header.classList.remove('is-open')
    })
  }
}

const setSlider = () => {
  const themeList = new Swiper('.p-theme-list', {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    loopedSlides: 3,
    pagination: {
      el: '.p-theme__pagination',
      type: 'bullets',
    },
    navigation: {
      nextEl: '.p-theme-list__nav--next',
      prevEl: '.p-theme-list__nav--prev',
    },
    autoplay: {
      delay: 5000,
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      980: {
        slidesPerView: 2,
        spaceBetween: 55
      },
    }
  });
}

const setFeatureModal = () => {
  const target = document.querySelector('.p-feature-modal'),
    bg = document.querySelector('.p-feature-modal__bg'),
    closeBtn = document.querySelector('.p-feature-modal__close');
  const featureList = new Swiper('.p-feature-modal__container', {
    slidesPerView: 1,
    spaceBetween: 80,
    allowTouchMove: false,
    loop: true,
    loopedSlides: 3,
    navigation: {
      nextEl: '.p-feature-modal__btn--next',
      prevEl: '.p-feature-modal__btn--prev',
    }
  });
  // setTimeout(() => {
  // featureList.params.speed = 1;
  // featureList.update();
  // featureList.slideTo(4, 1, false)
  // }, 3000)
  const featureModals = document.querySelectorAll('[data-feature-modal]');
  for (const featureModal of featureModals) {
    featureModal.addEventListener('click', () => {
      const num = parseInt(featureModal.dataset.featureModal);
      console.log(num)
      featureList.slideTo(num + 2, 1, false)
      setTimeout(() => {
        target.classList.add('is-active');
      }, 100)
    })
  }

  const closeModal = () => {
    target.classList.remove('is-active');
  }
  bg.addEventListener('click', closeModal);
  closeBtn.addEventListener('click', closeModal)
}



const setThemeModal = () => {
  const target = document.querySelector('.p-theme-modal'),
    bg = document.querySelector('.p-theme-modal__bg'),
    closeBtn = document.querySelector('.p-theme-modal__close');
  const themeList = new Swiper('.p-theme-modal__container', {
    slidesPerView: 1,
    spaceBetween: 80,
    loop: true,
    loopedSlides: 3,
    // allowTouchMove: false,
    navigation: {
      nextEl: '.p-theme-modal__btn--next',
      prevEl: '.p-theme-modal__btn--prev',
    }
  });
  // setTimeout(() => {
  // themeList.params.speed = 1;
  // themeList.update();
  // themeList.slideTo(4, 1, false)
  // }, 3000)
  const themeModals = document.querySelectorAll('.p-theme-list__card');
  for (const themeModal of themeModals) {
    themeModal.addEventListener('click', (e) => {
      e.preventDefault();
      const num = parseInt(themeModal.querySelector('[data-theme-num]').dataset.themeNum);
      console.log(num)
      themeList.slideTo(num + 2, 1, false)
      setTimeout(() => {
        target.classList.add('is-active');
      }, 100)
      return false;
    })
  }

  const closeModal = () => {
    target.classList.remove('is-active');
  }
  bg.addEventListener('click', closeModal);
  closeBtn.addEventListener('click', closeModal)
}

const setNews = () => {
  const btn = document.querySelector('.p-news__more-link');
  if (btn) {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector('.p-news__more').classList.toggle('is-shown');
      btn.classList.toggle('is-active');
      return false;
    })
  }
}

const setScroll = () => {
  const scrollIns = document.querySelectorAll('[data-scroll-in]');
  const checkScroll = () => {
    for (const scrollIn of scrollIns) {
      const pos = scrollIn.getBoundingClientRect().top;
      if (pos < bH * .75) {
        scrollIn.classList.add('is-shown');
      } else {
        scrollIn.classList.remove('is-shown');
      }
    }
  }
  checkScroll();
  window.addEventListener('scroll', checkScroll)
}